import styled from "styled-components";

export const AnalyticsWrapper = styled.div`
  height: 100%;
  width: 100%;

  .statistics {
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;
