import axios from "axios";
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Statistic, Card } from "antd";
import { AnalyticsWrapper } from "./styles";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PlaysByPlatform = ({ data }) => {
  if (!data) return <div></div>;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  let platforms = data.map((album) => {
    const p = Array.from(
      new Set(album.analyticsBL.map((analytic) => analytic.platform))
    );
    return p;
  });
  let finalPlatformList = [];

  platforms.forEach((platformList) => {
    return (finalPlatformList = finalPlatformList.concat(...platformList));
  });

  const dataset = [];

  data.forEach((album) => {
    album.analyticsBL.forEach((analytic) => {
      console.log(analytic);
      dataset.push({
        label: "Plays",
        data: analytic.sold,
      });
    });
  });

  const chartData = {
    labels: finalPlatformList,
    datasets: dataset,
  };

  return <Bar options={options} data={chartData} />;
};

const Statistics = ({ data }) => {
  if (!data) return <div></div>;

  let totalPlays = 0;
  let totalRevenue = 0;

  data.forEach((album) => {
    album.analyticsBL.forEach((analytic) => {
      totalPlays += parseInt(analytic.sold);
      totalRevenue += parseFloat(analytic.grossRevenue);
    });
  });

  const getRvenue = () => {
    let revenue = totalRevenue * 81.26;
    revenue = (70 / 100) * revenue;
    revenue = revenue.toFixed(3);
    return revenue;
  };

  return (
    <div className="statistics">
      <Card>
        <Statistic title="Albums Releases" value={data.length} />
      </Card>

      <Card>
        <Statistic title="Total Plays" value={totalPlays} />
      </Card>

      <Card>
        <Statistic title="Total Revenue" value={`₹ ${getRvenue()}`} />
      </Card>
    </div>
  );
};

export default function Analytics() {
  const [theData, setTheData] = React.useState();
  React.useEffect(() => {
    (async () => {
      const result = await axios.get("/analytics");
      console.log(result.data.data);
      setTheData(result.data.data);
    })();
  }, []);
  return (
    <AnalyticsWrapper>
      <Statistics data={theData} />
      <PlaysByPlatform data={theData}> </PlaysByPlatform>
    </AnalyticsWrapper>
  );
}
