import React from "react";
import Profile from "../../components/Profile";
import useTitle from "../../hooks/useTitle";
import { isLoggedIn } from "../../utils/auth";
import { AUTH_SCREEN } from "../../utils/url";

export default function ProfilePage() {
    useTitle("Profile");

    if (!isLoggedIn()) {
        window.location = '/';;
        return null;
    }

    return <Profile></Profile>;
}
