import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const setAuthCookies = (response, user) => {
  let expiry = new Date();
  expiry.setSeconds(expiry.getSeconds() + response.expires_in);
  let options = {
    path: "/",
    expires: expiry,
  };
  const refreshTokenExpiry = new Date();
  refreshTokenExpiry.setDate(refreshTokenExpiry.getDate() + 10);

  cookies.set("accessToken", response.access_token, options);
  cookies.set("refreshToken", response.refresh_token, {
    path: "/",
    expires: refreshTokenExpiry,
  });
  if (!user) {
    return;
  }
  cookies.set("userId", user._id, options);
  cookies.set("userEmail", user.email, options);
  cookies.set("username", user.username, options);
  cookies.set("userName", user.name || user.firstName + " " + user.lastName, options);
  cookies.set("role", user.role, options);
};

export const removeAllCookies = () => {
  cookies.remove("accessToken", { path: '/' });
  cookies.remove("refreshToken", { path: '/' });
  cookies.remove("userId", { path: '/' });
  cookies.remove("userEmail", { path: '/' });
  cookies.remove("username", { path: '/' });
  cookies.remove("userName", { path: '/' });
}

export const isLoggedIn = () => {
  let loggedIn = false;
  const cookies = new Cookies();
  if (cookies.get("refreshToken") && cookies.get("refreshToken").length >= 30)
    //If token stored in cookie is 30 or more characters, user is logged in.
    loggedIn = true;

  if (loggedIn === true) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + cookies.get("accessToken");
  }
  return loggedIn;
};

export const getLoggedInUser = () => {
  const cookies = new Cookies();

  const user = {
    _id: cookies.get("userId"),
    name: cookies.get("userName"),
    username: cookies.get("username"),
    email: cookies.get("userEmail"),
    display_picture: cookies.get("userDisplayPicture"),
    role: cookies.get("role") ? cookies.get("role").toLowerCase() : null
  };

  return user;
};
