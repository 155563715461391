import { Spin } from 'antd';
import React from 'react'

export default function PageLoader() {
    return <div style={{
        height: "100%",
        width: "100%",
        display: 'grid',
        placeItems: 'center'
    }}>
        <Spin />
    </div>;
}
