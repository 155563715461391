import React, { useEffect, useState } from 'react'
import { UsersWrapper } from './styles';
import { Divider, List, Skeleton } from 'antd';
import BoringAvatar from 'boring-avatars';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { Link } from "react-router-dom";

import { AUTH_URL } from '../../utils/url';
import routes from '../../routes/routes';
import { CheckCircleOutlined } from '@ant-design/icons';
import { NavButton } from '../common-components/Navigation/styles';
import { LinkOutlined } from '@ant-design/icons';

export default function Users() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [totalUsers, setTotalUsers] = useState(0);

    useEffect(() => setTotalUsers(data.length), [data]);

    const loadMoreData = () => {
        if (loading) {
            return;
        }
        axios
            .get(AUTH_URL + "/user/admin-api/list", {
                params: {
                    limit: 30,
                    offset: data.length > 1 ? data[data.length - 1]._id : null
                }
            })
            .then((response) => {
                let users = response.data.data.users;
                if (users.length < 30) {
                    setHasMoreData(false)
                }
                setData([...data, ...users]);
            })
            .finally(() => {
                setLoading(false);
            });
        setLoading(true);
    };
    useEffect(() => {
        loadMoreData();
    }, []);

    return (
        <UsersWrapper
            id="scrollableDiv"
            style={{
                height: "100%",
                overflow: 'auto',
                padding: '0 16px',
            }}
        >
            <div className="prompt">
                <div className="text">
                    {totalUsers} users in view
                    <NavButton active onClick={() => window.open("https://nitrogen.rayapprelease.com", '_blank').focus()}>
                        <center style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Manage on Nitrogen&nbsp;&nbsp;<LinkOutlined /></center>
                    </NavButton>
                </div>
            </div>
            <InfiniteScroll
                dataLength={data.length}
                next={loadMoreData}
                hasMore={hasMoreData}
                loader={
                    <Skeleton
                        avatar
                        paragraph={{
                            rows: 1,
                        }}
                        active
                    />
                }
                endMessage={<Divider plain>That's all for now!😄</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item key={item.email}>
                            <List.Item.Meta
                                avatar={
                                    <BoringAvatar
                                        size={"2rem"}
                                        name={window.btoa(item._id) || window.btoa(item.email)}
                                        variant="beam"
                                        colors={["#F2F4F5", "#FFBB1C", "#045C94", "#02314D", "#181E20"]}
                                    />
                                }
                                title={
                                    <Link onClick={() => window.open("https://nitrogen.rayapprelease.com/users/" + item._id, '_blank').focus()}>
                                        {item.firstName ? item.firstName + ' ' + item.lastName : item.name} {item.verified && <CheckCircleOutlined />}
                                    </Link>
                                }
                                description={item.email}
                            />
                            <div>{item.role.toLowerCase()}</div>
                        </List.Item>
                    )}
                />
            </InfiniteScroll>
        </UsersWrapper >
    );
}
