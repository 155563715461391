import styled from "styled-components";
import theme from "../../../styles/theme";

export const AlbumPreviewWrapper = styled.div`
  padding: 16px;
  height: 100%;
  overflow-y: auto;
  z-index: 1;

  .backdrop {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    opacity: 0.2;
    filter: blur(15px);
  }

  .ant-card, .ant-alert {
    border-radius: ${theme.container.borderRadius};
  }

  .ant-image {
    border-radius: ${theme.container.borderRadius};
    overflow: hidden;

    ${theme.media.mobile}, ${theme.media.portrait} {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .album-title {
    display: flex;
  }

  .ant-descriptions-view {
    margin-top: 12px;
    border-radius: ${theme.container.borderRadius};
    background-color: ${theme.colors.surface};
    box-shadow: none;
  }

  .ant-collapse {
    border-radius: ${theme.container.borderRadius}
  }

  .ant-collapse-content {
    .ant-descriptions-view {
      border: none;
    }
  }

  .section-heading {
    color: ${theme.colors.accent};
    margin: 24px 0;
    font-size: large;
    font-weight: normal;
  }

  .album-descriptions {
    .ant-descriptions-view {
      border-radius: ${theme.container.borderRadius};
      background-color: ${theme.colors.surface};
      box-shadow: none;
    }
  }

  .detail-label {
    font-weight: 450;
  }
`;
