import React, { useContext, useEffect, useState } from "react";
import { LoginWrapper } from "./styles";
import { useHistory } from "react-router-dom";
import { isLoggedIn, removeAllCookies, setAuthCookies } from "../../utils/auth";
import routes from "../../routes/routes";
import axios from "axios";
import { AUTH_SCREEN, AUTH_URL } from "../../utils/url";
import { Modal, Spin } from "antd";
import { Role } from "../../enum/role";
import { getConfig } from "../../utils/permissions";
import { AuthContext } from "../../context/auth";
import { PrimaryButton } from "../common-components/PrimaryButton/styles";

export default function Login() {
  const history = useHistory();
  const { setLoginData } = useContext(AuthContext);
  const [loginResolved, setLoginResolved] = useState(false);

  const redirectToDefaultPage = (user = { role: Role.ARTIST }) => {
    if (getConfig("privilege.can-view-all-releases")) {
      return history.push(routes.allReleases);
    }
    history.push(routes.myReleases);
  }

  useEffect(() => {
    var urlString = window.location.href;
    var url = new URL(urlString);
    var code = url.searchParams.get("code");
    var error = url.searchParams.get("error");
    var errorDesc = url.searchParams.get("error_description");
    if (error) {
      Modal.error({
        title: "Authentication Error: " + error,
        content: errorDesc,
      });
      return null;
    }
    if (isLoggedIn()) {
      return redirectToDefaultPage();
    } else if (code) {
      if (code) {
        const oauthApi = AUTH_URL + "/oauth/token";
        const params = new URLSearchParams()
        params.append('grant_type', 'authorization_code')
        params.append('client_id', 'application_client')
        params.append('redirect_uri', window.location.origin)
        params.append('code', code);
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
        axios.post(oauthApi, params, config).then((tokenResponse) => {
          tokenResponse = tokenResponse.data;
          setAuthCookies(tokenResponse);
          axios.get(AUTH_URL + "/user/me", {
            headers: {
              'Authorization': `Bearer ${tokenResponse.access_token}`
            }
          }).then((userResponse) => {
            const user = userResponse.data.data.user;
            setAuthCookies(tokenResponse, user);
            setLoginData({ user, tokenResponse });
            redirectToDefaultPage(user);
          });
        });
      }
    } else {
      removeAllCookies();
      setLoginResolved(true);
      /* return window.location = '/';; */
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginWrapper>
      <div className="login-container">
        <img
          src="https://www.rayapprelease.com/assets/images/logo-main.png"
          alt="RayApp Release"
          className="logo"
        ></img>

        {
          loginResolved
            ? <div className="button-group">
              <a href={AUTH_SCREEN}>
                <PrimaryButton style={{
                  width: 180,
                }}>
                  <b>Artist Login</b>
                </PrimaryButton>
              </a>
              <a href="https://rayapprelease.sonosuite.com">
                <PrimaryButton style={{ width: 180 }}>
                  <b>Premium Client Login</b>
                </PrimaryButton>
              </a>
            </div>
            : <>
              <Spin style={{ marginTop: 14 }} />
              <div style={{ marginTop: 14 }}>Please wait...</div>
            </>
        }
      </div>
    </LoginWrapper >
  );
}
