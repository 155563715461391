import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  Drawer,
  Form,
  List,
  message,
  notification,
  Progress,
  Row,
  Select,
  Tooltip,
  Upload,
} from "antd";
import { TrackListWrapper } from "./styles";
import { PrimaryButton } from "../../common-components/PrimaryButton/styles";
import { StyledInput } from "../../common-components/Input/styles";
import { StyledSelect } from "../../common-components/Select/styles";
import MicroPlayer from "../../common-components/MicroPlayer";
import axios from "axios";
import { genreList } from "../../../utils/genres";
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  FileSyncOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { isoLangs } from "../../../utils/languages";
import { API_URL } from "../../../utils/url";

export default function TrackList({ albumId, tracks, setTracks, apiPrefix }) {
  const [songEditVisible, setSongEditVisible] = useState(false);
  const [currentEditTrack, setCurrentEditTrack] = useState({});
  const [currentUploadingTrack, setCurrentUploadingTrack] = useState([]);
  const [currentDeleteTrack, setCurrentDeleteTrack] = useState(null);
  const [trackUploadProgress, setTrackUploadProgress] = useState({});

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    Object.keys(values).forEach((key) => {
      if (
        values[key] === "" ||
        values[key] === null ||
        values[key] === undefined
      ) {
        values[key] = "__delete__";
      }
    });

    axios
      .patch(apiPrefix + "/songs/" + currentEditTrack._id, { ...values })
      .then(() => {
        console.log("Album updated");
        notification.success({
          message: "Track edited successfully.",
        });
        setTracks((existingSongs) => {
          return existingSongs.map((track) => {
            if (track._id === currentEditTrack._id) {
              Object.keys(values).forEach((key) => {
                if (!values[key]) return;
                if (values[key] === "__delete__") track[key] = null;
                else track[key] = values[key];
              });
            }
            return track;
          });
        });
        closeSongEditor();
      })
      .catch((error) => {
        notification.error({
          message: "Error editing track.",
          description: error.response.data.error,
        });
      });
    console.log("values: ", values);
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [currentEditTrack]);

  const addNewTrack = () => {
    axios
      .post(apiPrefix + "/songs", { albumId })
      .then((response) => {
        const song = response.data.data.songInfo;
        setTracks((existingSongs) => [...existingSongs, song]);
      })
      .catch((error) => {
        notification.error({
          message: "Error creating track.",
          description: error.response.data.error,
        });
      });
  };

  const deleteSong = (songId) => {
    setCurrentDeleteTrack(songId);
    axios
      .delete("/songs/" + songId)
      .then(() => {
        setTracks((existingSongs) =>
          existingSongs.filter((s) => s._id !== songId)
        );
      })
      .catch((error) => {
        notification.error({
          message: "Error deleting track.",
          description: error.response.data.error,
        });
      })
      .finally(() => {
        setCurrentDeleteTrack(null);
      });
  };

  const closeSongEditor = () => {
    setSongEditVisible(false);
  };

  const openSongEditor = (track) => {
    setCurrentEditTrack(track);
    setSongEditVisible(true);
  };

  const { Option } = Select;

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function beforeUpload(file, track) {
    uploadTrack(file, track);
    return false;
  }

  const uploadTrack = async (file, track) => {
    const supportedTypes = ["audio/wav", "audio/mpeg", "audio/flac", "audio/x-wav"];
    const isAudioFile = supportedTypes.includes(file.type);
    if (!isAudioFile) {
      return message.error("Please upload a MP3, WAV or FLAC file");
    }

    const isLt100M = file.size / 1024 / 1024 < 100;
    if (!isLt100M) {
      return message.error("Master file must smaller than 100MB");
    }

    const formData = new FormData();
    formData.append("masterFilename", file);

    setCurrentUploadingTrack((currentUploadingTrack) => {
      const currentUploadingTrackCopy = [...currentUploadingTrack];
      currentUploadingTrackCopy.push(track._id);
      return currentUploadingTrackCopy;
    });

    const newTrack = { ...track };

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
          progressEvent.target.getResponseHeader(
            "x-decompressed-content-length"
          );
        if (totalLength !== null) {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / totalLength
          );
          setTrackUploadProgress((progress) => {
            const copy = { ...progress };
            copy[newTrack._id] = percentage;
            return copy;
          });
        }
      },
    };
    axios
      .put(API_URL + apiPrefix + "/songs/" + newTrack._id + "/master", formData, config)
      .then((response) => {
        const data = response.data.data;
        newTrack.masterUrl = data.signedUrl;
        notification.success({
          message: "File uploaded successfully.",
        });
        setTracks((oldTracks) =>
          oldTracks.map((t) => (t._id === newTrack._id ? newTrack : t))
        );
      })
      .catch((error) => {
        notification.error({
          message: "Error uploading track file.",
          description: error.response.data.error,
        });
      })
      .finally(() => {
        setCurrentUploadingTrack((currentUploadingTrack) =>
          currentUploadingTrack.filter((t) => t !== track._id)
        );
        setTrackUploadProgress((progress) => {
          const copy = { ...progress };
          copy[newTrack._id] = 0;
          return copy;
        });
      });
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      console.log("uploading...");
      return;
    }
  };

  const getUploadButtonContent = (track) => {
    if (currentUploadingTrack.includes(track._id))
      return (
        <div className="upload-progress-container">
          <Progress
            percent={trackUploadProgress[track._id]}
            showInfo={false}
            size="small"
            status="active"
          />
        </div>
      );

    if (track.masterUrl)
      return (
        <>
          <FileSyncOutlined />
          &nbsp;Change Audio
        </>
      );
    else
      return (
        <>
          <FileAddOutlined />
          &nbsp;Upload Audio
        </>
      );
  };

  return (
    <>
      <Drawer
        title="Edit Song Info"
        placement="right"
        closable={false}
        onClose={closeSongEditor}
        visible={songEditVisible}
        width={window.innerWidth > 769 ? 500 : "100%"}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <PrimaryButton onClick={closeSongEditor} style={{ marginRight: 8 }}>
              Cancel
            </PrimaryButton>
            <PrimaryButton onClick={() => form.submit()} type="primary">
              Submit
            </PrimaryButton>
          </div>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleFinish}
          initialValues={{
            title: currentEditTrack.title,
            primaryArtist: currentEditTrack.primaryArtist,
            secondaryArtist: currentEditTrack.secondaryArtist,
            composer: currentEditTrack.composer,
            lyricist: currentEditTrack.lyricist,
            explicit: currentEditTrack.explicit || false,
            mainGenre: currentEditTrack.mainGenre,
            subGenre: currentEditTrack.subGenre,
            language: currentEditTrack.language,
            ISRC: currentEditTrack.ISRC,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="title"
                label="Track Title"
                rules={[
                  { required: true, message: "Please enter track title" },
                  { max: 150, message: "Track title too long" },
                ]}
              >
                <StyledInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="ISRC"
                label="ISRC"
                rules={[
                  { min: 12, max: 12, message: "Please enter a valid ISRC" },
                ]}
              >
                <StyledInput
                  suffix={
                    <Tooltip title="If you don't have an ISRC, we'll assign you one.">
                      <InfoCircleOutlined
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="mainGenre"
                label="Main Genre"
                rules={[{ required: true, message: "Please enter main genre" }]}
              >
                <StyledSelect
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select a genre"
                  optionFilterProp="children"
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {genreList.map((genre) => (
                    <Option key={genre} value={genre}>{genre}</Option>
                  ))}
                </StyledSelect>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="subGenre"
                label="Sub Genre"
                rules={[{ required: true, message: "Please enter sub genre" }]}
              >
                <StyledSelect
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select a genre"
                  optionFilterProp="children"
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {genreList.map((genre) => (
                    <Option value={genre}>{genre}</Option>
                  ))}
                </StyledSelect>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Primary Artist :"
                name="primaryArtist"
                rules={[
                  {
                    required: true,
                    message: "Primary Artist is Required!",
                  },
                ]}
              >
                <StyledInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Secondary Artist :" name="secondaryArtist">
                <StyledInput />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Composer :"
                name="composer"
                extra="Enter composer's original first and last name."
                rules={[
                  {
                    required: true,
                    message: "Composer is Required!",
                  },
                ]}
              >
                <StyledInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Lyricist :"
                name="lyricist"
                rules={[
                  {
                    required: true,
                    message: "Lyricist is Required!",
                  },
                ]}
              >
                <StyledInput />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Language of release :"
                name="language"
                rules={[
                  {
                    required: true,
                    message: "Language of release is Required!",
                  },
                ]}
              >
                <StyledSelect
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select a language"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    console.log(option);
                    return (
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }}
                >
                  {Object.values(isoLangs).map((language) => (
                    <Option value={language.name}>
                      {language.name}({language.nativeName})
                    </Option>
                  ))}
                </StyledSelect>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="explicit" valuePropName="checked">
                  <Checkbox>Explicit</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Row>
        </Form>
      </Drawer>
      <TrackListWrapper className="fade-in">
        <List
          size="large"
          dataSource={tracks}
          renderItem={(track) => (
            <List.Item
              style={{ opacity: currentDeleteTrack === track._id ? 0.5 : 1 }}
              actions={[
                track.masterUrl ? (
                  <MicroPlayer source={track}></MicroPlayer>
                ) : null,
                <Upload
                  name="masterFilename"
                  action={(file) => uploadTrack(file, track)}
                  showUploadList={false}
                  onDrop={(file) => uploadTrack(file, track)}
                  beforeUpload={(file) => beforeUpload(file, track)}
                  onChange={handleChange}
                >
                  <span className="edit-link">
                    {getUploadButtonContent(track)}
                  </span>
                </Upload>,
                <span
                  className="edit-link"
                  onClick={() => openSongEditor(track)}
                >
                  <EditOutlined />
                  &nbsp;Edit Info
                </span>,
                <span
                  className="edit-link"
                  onClick={() => deleteSong(track._id)}
                >
                  <DeleteOutlined />
                  &nbsp;Delete
                </span>,
              ]}
            >
              {track.title || (
                <span
                  className="edit-link"
                  onClick={() => openSongEditor(track)}
                >
                  <i>
                    <PlusCircleOutlined />
                    &nbsp;Add Details
                  </i>
                </span>
              )}
            </List.Item>
          )}
        />
        <div className="add-link" onClick={addNewTrack}>
          <PlusCircleOutlined />
          &nbsp;Add Track
        </div>
      </TrackListWrapper>
    </>
  );
}
