import React, { useEffect, useState } from "react";
import { Steps, notification, Alert } from "antd";
import { StepperFormWrapper } from "./styles";
import BasicInfoForm from "./BasicInfoForm";
import TrackList from "./TrackList";
import { PrimaryButton } from "../common-components/PrimaryButton/styles";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import Loader from "../common-components/Loader";
import routes from "../../routes/routes";
import AlbumPreview from "./AlbumPreview";
import {
  CustomerServiceFilled,
  EditFilled,
  SmileFilled,
} from "@ant-design/icons";
import { getConfig } from "../../utils/permissions";

const { Step } = Steps;

const steps = [
  {
    id: 0,
    title: "Basic Info",
    icon: <EditFilled />,
  },
  {
    id: 1,
    title: "Track List",
    icon: <CustomerServiceFilled />,
  },
  {
    id: 2,
    title: "Review",
    icon: <SmileFilled />,
  },
];

const albumSchema = {
  title: null,
  primaryArtist: null,
  secondaryArtist: null,
  language: null,
  mainGenre: null,
  subGenre: null,
  releaseDate: null,
  productionYear: null,
  label: null,
  UPC: null,
  songs: [],
};

export default function NewRelease() {
  const [current, setCurrent] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const [album, setAlbum] = useState(albumSchema);

  const [tracks, setTracks] = useState([]);

  const history = useHistory();

  const { albumId } = useParams();

  const getAlbumDetails = () => axios.get("/albums/" + albumId);

  const apiPrefix = getConfig("privilege.can-use-api-prefix") ? "/admin" : "";

  useEffect(() => console.log("album updated", album), [album]);

  useEffect(() => {
    if (!albumId) return;

    setLoading(true);
    getAlbumDetails()
      .then((response) => {
        let album = response.data.data;
        if (album.title === undefined) album = { ...albumSchema, ...album };
        setAlbum(album);
        setTracks(album.songs);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const submitAlbum = () => {
    const confirmationMessage =
      "❗Please double check all the details of this release carefully before sending it for review. No change can be performed once you click on OK. Do you want to proceed to submit the release for review?";
    if (window.confirm(confirmationMessage))
      axios
        .patch("/albums/" + albumId + "/submit")
        .then(() => {
          notification.success({
            message: "Album submitted for review",
          });
          history.push(routes.myReleases);
        })
        .catch((error) => {
          notification.error({
            message: "Error submitting release",
            description: error.response.data.error,
          });
        });
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    const createAlbum = () => axios.post("/albums");

    if (!albumId) {
      setLoading(true);
      createAlbum()
        .then((response) => {
          const album = response.data.data.albumInfo;
          history.push("/edit-release/" + album._id);
        })
        .catch((error) => {
          notification.error({
            message: "Too many drafts",
            description:
              "Looks like you already have a couple of drafts in your library. Please clear or edit the existing ones.",
          });
          history.push(routes.myReleases);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, []);

  if (loading)
    return (
      <StepperFormWrapper>
        <Loader />
      </StepperFormWrapper>
    );

  if (!albumId) return null;

  const isTrackFieldNull = (fieldName) => {
    return !tracks.every((track) => {
      return (
        track[fieldName] !== undefined &&
        track[fieldName] !== null &&
        track[fieldName] !== ""
      );
    });
  };

  return (
    <StepperFormWrapper>
      {album.status === "Rejected" &&
        album.reviewComments &&
        album.reviewComments !== "" && (
          <Alert
            message="Your album was rejected due to the following reasons:"
            description={
              <pre className="review-comments">{album.reviewComments}</pre>
            }
            type="error"
            showIcon
          />
        )}
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} icon={item.icon} />
        ))}
      </Steps>
      <div className="steps-content">
        {current === 0 && (
          <BasicInfoForm
            nextStep={next}
            albumId={albumId}
            album={album}
            setAlbum={setAlbum}
            apiPrefix={apiPrefix}
          />
        )}
        {current === 1 && (
          <TrackList albumId={albumId} tracks={tracks} setTracks={setTracks} apiPrefix={apiPrefix} />
        )}
        {current === 2 && <AlbumPreview album={album} setAlbum={setAlbum} tracks={tracks} />}
      </div>
      {current === steps.length - 1 && (
        <div className="fine-print">
          <span>
            By clicking on Submit, you agree to the&nbsp;<a href="/terms-of-release" target="_blank">terms and conditions of release.</a>
          </span>
        </div>)
      }
      <div className="steps-action">
        {current > 0 && (
          <PrimaryButton style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </PrimaryButton>
        )}
        {current > 0 && current < steps.length - 1 && (
          <PrimaryButton
            type="primary"
            onClick={() => next()}
            disabled={
              !tracks.length ||
              isTrackFieldNull("title") ||
              isTrackFieldNull("masterUrl")
            }
          >
            Next
          </PrimaryButton>
        )}
        {current === steps.length - 1 && (
          <PrimaryButton type="primary" onClick={submitAlbum}>
            Submit for review
          </PrimaryButton>
        )}
      </div>
    </StepperFormWrapper >
  );
}
