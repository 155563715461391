import React from "react";
import NewRelease from "../../components/NewRelease";
import useTitle from "../../hooks/useTitle";
import { isLoggedIn } from "../../utils/auth";
import { AUTH_SCREEN } from "../../utils/url";

export default function NewReleasepage() {
  useTitle("New Release");

  if (!isLoggedIn()) {
    window.location = '/';;
    return null;
  }

  return <NewRelease></NewRelease>;
}
