import React from "react";
import Configure from "../../components/Configure";
import useTitle from "../../hooks/useTitle";
import { isLoggedIn } from "../../utils/auth";
import { AUTH_SCREEN } from "../../utils/url";

export default function ConfigurePage() {
    useTitle("Configure");

    if (!isLoggedIn()) {
        window.location = '/';;
        return null;
    }

    return <Configure></Configure>;
}
