import styled from "styled-components";
import theme from "../../styles/theme";

export const UsersWrapper = styled.div`
padding: 32px;
.prompt {
    width: 100%;
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 1000;

    .text {
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;
        background: ${theme.colors.surface};
        border-bottom-left-radius: ${theme.container.borderRadius};
        border-bottom-right-radius: ${theme.container.borderRadius};
        border: 1px solid ${theme.colors.textOnSurface}1D;
        border-top: none;
    }
}
`;