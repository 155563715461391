import styled from "styled-components";
import theme from "../../styles/theme";

export const ConfigureWrapper = styled.div`
padding: 32px;

.ant-card-body{
    padding: 16px !important;
}

.width-145{
    width: 145px !important;
}
.list {
    list-style-type: none;

    .sortable-item {
        margin: 16px;
        padding: 16px;
        background: ${theme.colors.surface};
        border-radius: ${theme.container.borderRadius};
        border: 1px solid ${theme.colors.textOnBackground}1D;
    }
}
`;