import styled from "styled-components";
import theme from "../../styles/theme";

export const StepperFormWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 3rem 10rem;
  overflow-y: auto;

  .ant-steps-item-content {
    display: inline-flex;
    align-items: center;

    .ant-steps-item-title {
      font-size: small;
      font-weight: 500;
    }
  }

  .fine-print {
    margin: 16px 0;
    font-size: small;
    display: flex;
    justify-content: right;
    text-align: right;

    ${theme.media.mobile}, ${theme.media.portrait} {
      a {
        display: block;
      }
      justify-content: left;
      text-align: left;
      margin: 16px;
    }
  }

  .steps-action {
    float: right;
  }

  .ant-alert {
    margin-bottom: 24px;
  }

  .review-comments {
    white-space: wrap;
    font-family: unset;
  }

  ${theme.media.mobile}, ${theme.media.portrait} {
    padding: 3rem 1rem;
  }
`;
