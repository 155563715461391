import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { MicroPlayerWrapper } from "./styles";

export default function MicroPlayer({ source }) {
  const audioRef = useRef(new Audio());
  useEffect(() => {
    audioRef.current.src = source.masterUrl;
    setPlaying(false);
  }, [source]);
  const [playing, setPlaying] = useState(false);

  const playPause = () => {
    if (playing) {
      audioRef.current.pause();
      setPlaying(false);
    } else {
      audioRef.current.play();
      setPlaying(true);
    }
  };

  audioRef.current.onended = () => setPlaying(false);

  return (
    <MicroPlayerWrapper>
      <div className="play-button" onClick={playPause}>
        {playing ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
      </div>
    </MicroPlayerWrapper>
  );
}
