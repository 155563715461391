import React from "react";
import AllReleases from "../../components/AllReleases";
import useTitle from "../../hooks/useTitle";
import { isLoggedIn } from "../../utils/auth";
import { AUTH_SCREEN } from "../../utils/url";

export default function AllReleasesPage({ setPageTitle }) {
  useTitle("Admin");

  if (!isLoggedIn()) {
    window.location = '/';;
    return null;
  }

  return <AllReleases setPageTitle={setPageTitle}></AllReleases>;
}
