import React from "react";
import ViewRelease from "../../components/ViewRelease";
import useTitle from "../../hooks/useTitle";
import { isLoggedIn } from "../../utils/auth";
import { AUTH_SCREEN } from "../../utils/url";

export default function ViewReleasePage() {
  useTitle("Preview");

  if (!isLoggedIn()) {
    window.location = '/';;
    return null;
  }

  return <ViewRelease></ViewRelease>;
}
