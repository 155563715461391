import styled from "styled-components";
import theme from "../../../styles/theme";

export const TrackListWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  overflow-x: auto;

  .ant-list-item {
    background-color: ${theme.colors.surface};
    border: 1px solid ${theme.colors.textOnSurface}1D;
    margin: 1.5rem 0;
    border-radius: 8px;
    overflow-x: auto;
    /* box-shadow: -1px 2px 8px -1px rgb(0 0 0 / 10%); */

    .edit-link {
      cursor: pointer;
    }

    .ant-list-item-action li {
      color: ${theme.colors.textOnSurface} !important;
    }
  }

  .ant-list-item:last-child {
    border-bottom: 1px solid ${theme.colors.textOnSurface}1D;
  }

  .add-link {
    width: 100%;
    margin: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    opacity: 0.6;
    cursor: pointer;

    :hover {
      opacity: 1;
    }
  }

  .upload-progress-container {
    .anticon {
      margin-right: 4px;
    }

    width: 100px;
    display: flex;
    align-items: baseline;
  }

  ${theme.media.mobile}, ${theme.media.portrait} {
    .ant-list-item {
      padding: 8px;
    }
  }
`;
