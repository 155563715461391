import React from 'react'
import { TermsOfReleaseWrapper } from './styles'

export default function TermsOfRelease() {
    return (
        <TermsOfReleaseWrapper>
            <h3>Terms of Release</h3>
            <div>This page is unavailable at the moment.</div>
        </TermsOfReleaseWrapper>
    )
}
