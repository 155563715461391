import React, { useContext } from 'react'
import { AuthContext } from '../../../context/auth';
import BoringAvatar from 'boring-avatars';

export default function Avatar() {
    const { loginData } = useContext(AuthContext);
    if (!loginData) {
        return null;
    }
    const user = loginData.user;
    return (
        <>
            <BoringAvatar
                size={"1rem"}
                name={window.btoa(user._id) || window.btoa(user.userEmail)}
                variant="beam"
                colors={["#F2F4F5", "#FFBB1C", "#045C94", "#02314D", "#181E20"]}
            />
        </>
    )
}
