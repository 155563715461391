import React from "react";
import useTitle from "../../hooks/useTitle";
import { isLoggedIn } from "../../utils/auth";
import { AUTH_SCREEN } from "../../utils/url";
import Users from "../../components/Users";

export default function UsersPage() {
    useTitle("Users");

    if (!isLoggedIn()) {
        window.location = '/';;
        return null;
    }

    return <Users></Users>;
}
