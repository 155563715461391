import React, { useEffect } from "react";
import Analytics from "../../components/Analytics";
import useTitle from "../../hooks/useTitle";
import { isLoggedIn } from "../../utils/auth";
import { AUTH_SCREEN } from "../../utils/url";

export default function AnalyticsPage({ setPageTitle }) {
  useTitle("Analytics");

  useEffect(() => {
    setPageTitle("Analytics");
  });

  if (!isLoggedIn()) {
    window.location = '/';;
    return null;
  }

  return <Analytics />;
}
