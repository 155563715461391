import styled from "styled-components";
import theme from "../../../styles/theme";

export const PageHeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 63px;
  background-color: ${theme.colors.surface};
  border-bottom: 1px solid ${theme.colors.textOnSurface}1D;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;

  .hamburger-icon {
    display: none;
  }

  ${theme.media.mobile}, ${theme.media.portrait} {
    border-top: 1px solid ${theme.colors.textOnSurface}1D;
    .hamburger-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 12px;
      right: 10px;
      cursor: pointer;
      width: 34px;
      height: 34px;
      border-radius: 100%;

      :hover {
        background-color: ${({ active, disabled }) => {
    if (active) return theme.colors.accent;
    if (disabled) return "transparent";
    else return theme.colors.buttonActive;
  }};
      outline: 1px solid ${theme.colors.textOnBackground}22;
      }
    }
    padding: 0 1rem;
    width: 100%;
  }
`;
