import { Role } from "../enum/role";
import { getLoggedInUser } from "./auth";

const permissionGraph = {
    "privilege.can-view-all-releases": [Role.SUPER_ADMIN, Role.ADMIN, Role.CONTENT_MODERATOR],
    "privilege.can-configure-application": [Role.SUPER_ADMIN, Role.ADMIN],
    "privilege.can-delete-releases": [Role.SUPER_ADMIN, Role.ADMIN],
    "privilege.can-edit-releases": [Role.SUPER_ADMIN, Role.ADMIN, Role.CONTENT_MODERATOR],
    "privilege.can-change-release-status": [Role.SUPER_ADMIN, Role.ADMIN, Role.CONTENT_MODERATOR],
    "privilege.can-use-multi-select": [Role.SUPER_ADMIN, Role.ADMIN, Role.CONTENT_MODERATOR],
    "privilege.can-use-api-prefix": [Role.SUPER_ADMIN, Role.ADMIN, Role.CONTENT_MODERATOR],
    "privilege.can-use-mini-player": [Role.ARTIST],
    "privilege.can-can-download-master": [Role.SUPER_ADMIN, Role.ADMIN, Role.CONTENT_MODERATOR, Role.ARTIST],
    "privilege.can-edit-user": [Role.SUPER_ADMIN, Role.ADMIN, Role.ARTIST]
}

export const getConfig = (feature) => {
    const { role } = getLoggedInUser();
    if (permissionGraph[feature].includes(role)) {
        return true;
    }
    return false;
}