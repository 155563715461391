import styled from "styled-components";
import theme from "../../styles/theme";

export const ProfileWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 3rem 10rem;
  overflow-y: auto;

  ${theme.media.mobile}, ${theme.media.portrait} {
    padding: 3rem 1rem;
  }

  .ant-form-item-control,
  .ant-form-item-label {
    max-width: unset;
  }
  
  .flex-row {
      display: flex;
      flex-direction: row;
      width: 100%;

      .left,
      .right {
        width: 50%;
      }

      .left {
        padding-right: 1rem;
      }
    }
`;