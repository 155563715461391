import React, { useEffect } from 'react'
import { ProfileWrapper } from './styles'
import { Card, Form, notification, } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons';
import { StyledInput } from '../common-components/Input/styles'
import axios from 'axios';
import { AUTH_URL } from '../../utils/url';
import { useParams } from 'react-router-dom';
import { PrimaryButton } from '../common-components/PrimaryButton/styles';
import PageLoader from '../common-components/PageLoader';
import { getConfig } from '../../utils/permissions';

export default function Profile() {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(true);
    const [user, setUser] = React.useState(null);

    const { id } = useParams();

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const tailLayout = {
        wrapperCol: {
            span: 16,
        },
    };

    const onFinish = (values) => {
        console.log("Submit form", values);
        const payload = {
            firstName: values.firstName,
            lastName: values.lastName,
            organization: values.organization
        }

        if (values.password) {
            payload.password = values.password
        }

        if (id) {
            payload.target = id;
        }

        const path = id ? "/user/admin-api/user" : "/user/me";
        axios
            .patch(AUTH_URL + path, payload)
            .then(() => {
                notification.success({
                    message: "Changes saved."
                });
            })
            .catch((error) => {
                notification.error({
                    message: "Error editing profile.",
                    description: error.response.data.error,
                });
            })
    };

    const suspend = () => {
        const state = !user.isBanned;
        const path = "/user/admin-api/ban"
        axios.post(AUTH_URL + path, { target: id, state: state })
            .then(() => {
                setUser(user => {
                    const userCopy = { ...user };
                    userCopy.isBanned = state;
                    return userCopy;
                });
            })
            .catch(() => {
                // Do something
            });
    }
    useEffect(() => {
        setLoading(true);
        const path = id ? "/user/" + id : "/user/me";
        axios.get(AUTH_URL + path)
            .then((response) => {
                let user = response.data.data.user;
                setUser(user)
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    if (loading || !user) {
        return <PageLoader />
    }

    return (
        <ProfileWrapper>
            <Card title="Profile">
                <Form
                    {...layout}
                    name="basic"
                    layout="vertical"
                    className="w-100"
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        username: user.username,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        role: user.role,
                        organization: user.organization
                    }}
                >
                    <Form.Item
                        label="Username"
                        name="username"
                    >
                        <StyledInput disabled />
                    </Form.Item>
                    <div className="flex-row">
                        <div className="left">
                            {console.log(user)}
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[
                                    {
                                        required: true,
                                        message: "First Name is Required!",
                                    },
                                ]}
                            >
                                <StyledInput />
                            </Form.Item>
                        </div>
                        <div className="right">
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        message: "Last Name is Required!",
                                    },
                                ]}
                            >
                                <StyledInput />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item
                        label="Email"
                        name="email"
                    >
                        <StyledInput disabled />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                    >
                        <StyledInput type="password" style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                        label="Label Name"
                        name="organization"
                    >
                        <StyledInput />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <PrimaryButton
                            type="primary"
                            htmlType="submit"
                            className="submit-btn"
                        >
                            {"Save"}
                        </PrimaryButton>
                    </Form.Item>
                </Form>
            </Card>
            {getConfig("privilege.can-edit-user")
                && id
                && <Card title="Administration" style={{ marginTop: "24px" }}>
                    <PrimaryButton type="primary" danger onClick={suspend}>
                        <CloseCircleOutlined />
                        {user.isBanned ? "Suspended" : "Suspend"}
                    </PrimaryButton>
                </Card>}
        </ProfileWrapper>
    )
}
