import React from "react";
import TermsOfRelease from "../../components/TermsOfRelease";
import useTitle from "../../hooks/useTitle";
import { isLoggedIn } from "../../utils/auth";
import { AUTH_SCREEN } from "../../utils/url";

export default function TermsOfReleasepage() {
    useTitle("Terms of Release");

    if (!isLoggedIn()) {
        window.location = '/';;
        return null;
    }

    return <TermsOfRelease></TermsOfRelease>;
}
