import React from "react";
import MyReleases from "../../components/MyReleases";
import useTitle from "../../hooks/useTitle";
import { isLoggedIn } from "../../utils/auth";

export default function MyReleasesPage() {
  useTitle("My Releases");

  if (!isLoggedIn()) {
    window.location = '/';
    return null;
  }

  return <MyReleases></MyReleases>;
}
