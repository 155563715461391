import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navigation from "./components/common-components/Navigation";
import NewReleasepage from "./pages/NewRelease";
import { Page, PageContent } from "./components/common-components/Page/styles";
import PageHeader from "./components/common-components/PageHeader";
import { useEffect, useState } from "react";
import MyReleasesPage from "./pages/MyReleases";
import LoginPage from "./pages/Login";
import axios from "axios";
import { API_URL, AUTH_URL } from "./utils/url";
import routes from "./routes/routes";
import AnalyticsPage from "./pages/Analytics";
import AllReleasesPage from "./pages/AllReleases";
import ViewReleasePage from "./pages/ViewRelease";
import { getLoggedInUser, isLoggedIn, removeAllCookies, setAuthCookies } from "./utils/auth";
import Cookies from "universal-cookie";
import { AuthContext } from "./context/auth";
import TermsOfReleasepage from "./pages/TermsOfRelease";
import ProfilePage from "./pages/Profile";
import ConfigurePage from "./pages/Configure";
import UsersPage from "./pages/Users";
import { useHistory } from "react-router-dom";

const cookies = new Cookies();

axios.defaults.baseURL = API_URL;

function App() {
  const [navigationExpanded, setNavigationExpanded] = useState(true);
  const [activeNav, setActiveNav] = useState(0);
  const [pageTitle, setPageTitle] = useState("");
  const [appInitialized, setAppInitialized] = useState(false);
  const [loginData, setLoginData] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const mobileMedia = window.matchMedia("only screen and (max-width:960px)");
    const portraitMedia = window.matchMedia("(orientation: portrait)");

    const onMediaChange = () => {
      setNavigationExpanded(true);
    };

    mobileMedia.addEventListener("change", onMediaChange);
    portraitMedia.addEventListener("change", onMediaChange);

    let interceptor;

    if (isLoggedIn()) {
      if (cookies.get("accessToken")) {
        setLoginData({
          user: getLoggedInUser(),
          tokenResponse: {
            access_token: cookies.get("accessToken"),
            refresh_token: cookies.get("refreshToken")
          }
        });
      }

      interceptor = axios.interceptors.request.use(
        async function (config) {
          try {
            if (cookies.get("accessToken") || config.url.includes("/oauth")) {
              return config;
            }
            const oauthApi = AUTH_URL + "/oauth/token";
            const params = new URLSearchParams()
            params.append('grant_type', 'refresh_token')
            params.append('client_id', 'application_client')
            params.append('refresh_token', cookies.get("refreshToken"));
            const payload = {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }
            let tokenResponse = await axios.post(oauthApi, params, payload)
            tokenResponse = tokenResponse.data;
            setAuthCookies(tokenResponse);
            let userResponse = await axios.get(AUTH_URL + "/user/me", {
              headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
            })
            const user = userResponse.data.data.user;
            setAuthCookies(tokenResponse, user);
            setLoginData({ user, tokenResponse });
            axios.defaults.headers.common["Authorization"] = `Bearer ${tokenResponse.access_token}`;
            config.headers.Authorization = `Bearer ${tokenResponse.access_token}`;
            return config;
          } catch (error) {
            if ((error && error.response && (error.response.status === 400 || error.response.status === 401))) {
              removeAllCookies();
              window.location = '/';
            } else {
              console.error(error)
              return Promise.reject(error);
            }
          }
        },
        error => {
          Promise.reject(error)
        });
    }
    setAppInitialized(true);

    return () => {
      setAppInitialized(false);
      mobileMedia.removeEventListener("change", onMediaChange);
      portraitMedia.removeEventListener("change", onMediaChange);
      axios.interceptors.request.eject(interceptor);
    };
  }, []);

  if (!appInitialized) return null;

  return (
    <div className="App">
      <Router>
        <AuthContext.Provider value={{ loginData, setLoginData }}>
          <Navigation
            navigationExpanded={navigationExpanded}
            setNavigationExpanded={setNavigationExpanded}
            activeNav={activeNav}
            setActiveNav={setActiveNav}
            setPageTitle={setPageTitle}
          ></Navigation>
          <Switch>
            <Page>
              <PageHeader
                setNavigationExpanded={setNavigationExpanded}
                pageTitle={pageTitle}
              ></PageHeader>
              <PageContent>
                <Route exact path="/">
                  <LoginPage setPageTitle={setPageTitle} />
                </Route>
                <Route exact path={routes.newRelease}>
                  <NewReleasepage />
                </Route>
                <Route exact path={routes.editRelease + "/:albumId"}>
                  <NewReleasepage />
                </Route>
                <Route exact path={routes.myReleases}>
                  <MyReleasesPage />
                </Route>
                <Route exact path={routes.allReleases}>
                  <AllReleasesPage setPageTitle={setPageTitle} />
                </Route>
                <Route exact path={routes.viewRelease + "/:albumId"}>
                  <ViewReleasePage setPageTitle={setPageTitle} />
                </Route>
                <Route exact path={routes.analytics}>
                  <AnalyticsPage setPageTitle={setPageTitle} />
                </Route>
                <Route exact path={routes.me}>
                  <ProfilePage setPageTitle={setPageTitle} />
                </Route>
                <Route exact path={routes.configure}>
                  <ConfigurePage setPageTitle={setPageTitle} />
                </Route>
                <Route exact path={routes.user + "/:id"}>
                  <ProfilePage setPageTitle={setPageTitle} />
                </Route>
                <Route exact path={routes.users}>
                  <UsersPage setPageTitle={setPageTitle} />
                </Route>
                <Route exact path={routes.termsOfRelease}>
                  <TermsOfReleasepage setPageTitle={setPageTitle} />
                </Route>
              </PageContent>
            </Page>
          </Switch>
        </AuthContext.Provider>
      </Router>
    </div>
  );
}

export default App;
