import styled from "styled-components";
import theme from "../../../styles/theme";

export const NavigationWrapper = styled.div`
  width: 300px;
  height: 100%;
  background-color: ${theme.colors.surface};
  border-right: 1px solid ${theme.colors.textOnSurface}1D;
  z-index: calc(${theme.baseZIndex} + 5);
  display: flex;
  flex-direction: column;
  align-items: center;

  ${theme.media.mobile}, ${theme.media.portrait} {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border-right: none;
  }

  .navigation-header {
    width: calc(100% - 4rem);
    padding: 2rem;
    position: relative;

    .logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: auto;
        height: 60px;
        image-rendering: -moz-crisp-edges; /* Firefox */
        image-rendering: -o-crisp-edges; /* Opera */
        image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
      }

      .logo-text {
        font-size: small;
        font-weight: medium;
        color: ${theme.colors.textOnSurface};
      }
    }

    .button-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 3rem;
    }

    ${theme.media.mobile}, ${theme.media.portrait} {
      height: 100%;
      display: flex;
      flex-direction: column;

      .button-wrapper {
        flex: 1;
        margin-top: 0;
      }
    }
  }

  .hamburger-icon {
    display: none;
  }

  ${theme.media.mobile}, ${theme.media.portrait} {
    .hamburger-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      bottom: 12px;
      right: 10px;
      cursor: pointer;
      width: 34px;
      height: 34px;
      border-radius: 100%;

      :hover {
        background-color: ${({ active, disabled }) => {
    if (active) return theme.colors.accent;
    if (disabled) return "transparent";
    else return theme.colors.buttonActive;
  }};
      outline: 1px solid ${theme.colors.textOnBackground}22;
      }
    }

    animation: slideIn 0.2s forwards;

    @keyframes slideIn {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  .user-details {
    position: absolute;
    bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .ant-avatar {
      margin: 0 6px;
    }

    .name {
      font-weight: 500;
      color: ${theme.colors.textOnSurface};
    }

    .logout-text {
      text-decoration: none;
      cursor: pointer;
      font-size: small;
      color: ${theme.colors.textOnSurface};
    }
  }
`;

export const NavButton = styled.div`
  border-radius: ${theme.button.borderRadius};
  margin: 4px 0;
  width: 13rem;
  padding: 14px 16px;
  text-align: left;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.1s ease-in-out;
  user-select: none;
  outline: 1px solid transparent;

  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  color: ${({ active, disabled }) => {
    if (disabled) return theme.colors.textOnSurface + "50";
    if (active) return theme.colors.textOnAccent;
    else return theme.colors.textOnSurface;
  }};
  box-shadow: ${({ active }) =>
    active ? "-1px 2px 8px -1px rgba(0, 0, 0, 0.2)" : "none"};
  background-color: ${({ active, disabled }) => {
    if (disabled) return "transparent";
    if (active) return theme.colors.accent;
    else return "transparent";
  }};

  :hover {
    background-color: ${({ active, disabled }) => {
    if (active) return theme.colors.accent;
    if (disabled) return "transparent";
    else return theme.colors.buttonActive;
  }};
  outline: 1px solid ${theme.colors.textOnBackground}22;
  }
`;
